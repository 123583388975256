<template>
    <!--brands-->
    <section class="brands brands-area " id="brands" style="background-color: white;" >
        <div class="container pb-66">
        <div class="my-container text-center">
            <VueSlickCarousel v-if="images" :arrows="false" :dots="false"  :rtl="true" :autoplay="true" :speed="1000" :slidesToShow="5">

                <div class="item" >
                    <div class="brand-icon" >
                        <img class="image" src="./../../../public/assets/images/brands/coelmo.webp" alt="coelmo">
                    </div>
                </div>

                <div class="item" >
                    <div class="brand-icon" >
                        <img class="image" src="./../../../public/assets/images/brands/frater.webp" alt="frater">
                    </div>
                </div>

                <div class="item" >
                    <div class="brand-icon" >
                        <img class="image" src="./../../../public/assets/images/brands/schneider.webp" alt="schneider">
                    </div>
                </div>

                <div class="item" >
                    <div class="brand-icon" >
                        <img class="image" src="./../../../public/assets/images/brands/makelsan.webp" alt="makelsan">
                    </div>
                </div>

                <div class="item" >
                    <div class="brand-icon" >
                        <img class="image" src="./../../../public/assets/images/brands/avolux.webp" alt="avolux">
                        <!-- <img class="image" src="./../../../public/assets/images/brands/sylcain.png"> -->
                    </div>
                </div>

                <div class="item" >
                    <div class="brand-icon" >
                        <img class="image" src="./../../../public/assets/images/brands/vekta.webp" alt="vekta">
                    </div>
                </div>

                <div class="item" >
                    <div class="brand-icon" >
                        <img class="image" src="./../../../public/assets/images/brands/eec.webp" alt="eec">
                    </div>
                </div>

              <div class="item" >
                <div class="brand-icon" >
                  <img class="image" src="./../../../public/assets/images/brands/atm-lighting.webp" alt="atm-lighting">
                </div>
              </div>

              <div class="item" >
                <div class="brand-icon" >
                  <img class="image" src="./../../../public/assets/images/brands/zambelis-lighting.webp" alt="zambelis-lighting">
                </div>
              </div>

              <div class="item" >
                <div class="brand-icon" >
                  <img class="image" src="./../../../public/assets/images/brands/nova-luce.webp" alt="nova-luce">
                </div>
              </div>

              <div class="item" >
                <div class="brand-icon" >
                  <img class="image" src="./../../../public/assets/images/brands/electron.webp" alt="electron">
                </div>
              </div>

              <div class="item" >
                <div class="brand-icon" >
                  <img class="image" src="./../../../public/assets/images/brands/holectron.webp" alt="electron">
                </div>
              </div>

              <div class="item" >
                <div class="brand-icon" >
                  <img class="image" src="./../../../public/assets/images/brands/kkdc-logo.webp" alt="electron">
                </div>
              </div>

              <div class="item" >
                <div class="brand-icon">
                  <img  class="image" src="./../../../public/assets/images/brands/oliptek-logo.webp" alt="electron">
                </div>
              </div>

              <div class="item" >
                <div class="brand-icon" >
                  <img class="image" src="./../../../public/assets/images/brands/3Brothers-Logo---Horizontally-1.webp" alt="electron">
                </div>
              </div>


            </VueSlickCarousel>
        </div>
        </div>
    </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    data() {
        return {
            images :[
                "assets/images/brands/coelmo.png",
                'assets/images/brands/frater.jpg',
                'assets/images/brands/schneider.png',
                'assets/images/brands/makelsan.png',
                'assets/images/brands/sylcania.png',
                'assets/images/brands/vekta.png',
            ]
        }
    },
    components: { VueSlickCarousel },
}
</script>



<style>
    .image {
        width: 120px !important;
        height: 60px;
        padding-left: 10px;
        padding-right: 10px;
    }

    @media(max-width :1000px) {
        .slick-track{
            height: 100px;
        }

        .slick-slide{
            width:120px;
        }
    }

    /* .brands{
        width: 100%;
        height: auto;
        text-align: center;
        padding: 20px 0;
        position: relative;
    }
    .brand-icon {
        height: 50px;
        width: 100px;
        margin: auto;
    }
    .brands .brand-icon {
    height: 50px;
    width: 100px;
    margin: auto;
} */
</style>
